import ScrolledToBlockDepthHandler from "./ScrolledToBlockDepthHandler";

export default class ScrolledToStoryContentBlockDepthHandler extends ScrolledToBlockDepthHandler {
    /**
     * @param {string} selector - css selector of the dom node to watch
     */
    constructor(selector) {
        super(selector, "storyContent");
    }
}
