export class LightBoxVideo {

    constructor(parentElement, videoComponent) {
        let lightBoxVideoElement = parentElement.getElementsByClassName("lightBoxVideo")[0];

        parentElement.addEventListener("click", (e) => {
            if (!lightBoxVideoElement.classList.contains("lightBoxVideo--display")) {
                this.#openLightBoxVideo(videoComponent, lightBoxVideoElement);
            }

            if(e.target.classList.contains("lightBoxVideo") || e.target.classList.contains("closeButton")) {
                this.#closeLightBoxVideo(videoComponent, lightBoxVideoElement, parentElement);
            }
        });

        parentElement.addEventListener("mouseover", () => {
            parentElement.getElementsByClassName("buttonVideoPlay")[0].classList.add("buttonVideoPlay--hover");
        });

        parentElement.addEventListener("mouseout", () => {
            parentElement.getElementsByClassName("buttonVideoPlay")[0].classList.remove("buttonVideoPlay--hover");
        });
    }

    #openLightBoxVideo(videoComponent, lightBoxVideoElement) {
        lightBoxVideoElement.classList.add("lightBoxVideo--display");
        document.body.classList.add("body--disabled");
        videoComponent.play();
    }

    #closeLightBoxVideo(videoComponent, lightBoxVideoElement, parentElement) {
        parentElement.getElementsByClassName("buttonVideoPlay")[0].classList.remove("buttonVideoPlay--hover");
        lightBoxVideoElement.classList.remove("lightBoxVideo--display");
        document.body.classList.remove("body--disabled");
        videoComponent.pause();
    }
}
