import HandlerAbstract from "./HandlerAbstract";

export default class VideoHandler extends HandlerAbstract {

    _stepReached = {};

    _hasAlreadyBeenFullscreen = {};

    getEventHandlers() {
        return ["videoReady", "videoStarted", "videoUnpaused", "videoPaused", "videoTimeupdate", "videoEnteredFullscreen"];
    }

    handle(evt) {

        let videoPlayer = evt.targetVideoPlayer;

        let videoData = {
            videoId: videoPlayer.getAttribute("data-video-id"),
            videoTitle: videoPlayer.getAttribute("data-video-title")
        };

        let methodName = "_handle" + evt.type[0].toUpperCase() + evt.type.slice(1);
        return this[methodName] !== undefined ? this[methodName].call(this, videoPlayer, videoData, evt.targetVideoCmp) : true;
    }

    _handleVideoReady(videoPlayer, videoData) {
        this._analytics.pubSub.publish("videoReady", Object.assign(videoData, {
            "durationInMS": Math.round(videoPlayer.duration() * 1000)
        }));
    }

    _handleVideoStarted(videoPlayer, videoData, videoCmp) {
        this._analytics.pubSub.publish("videoStarted", Object.assign(videoData, {
            "autoplay": (videoCmp.hasAutoplayed().toString())
        }));
    }

    _handleVideoUnpaused(videoPlayer, videoData) {
        this._handleVideoPlayPauseEvents("videoUnpaused", videoPlayer, videoData);
    }

    _handleVideoPaused(videoPlayer, videoData) {
        this._handleVideoPlayPauseEvents("videoPaused", videoPlayer, videoData);
    }

    _handleVideoPlayPauseEvents(eventName, videoPlayer, videoData) {
        this._analytics.pubSub.publish(eventName, Object.assign(videoData, {
            "position": Math.round(videoPlayer.currentTime() * 1000),
        }));
    }

    _handleVideoTimeupdate(videoPlayer, videoData) {
        let currentPercent = Math.round(videoPlayer.currentTime() * 100 / videoPlayer.duration());
        let currentStepReached = Math.floor(currentPercent / 25);
        if (currentStepReached > (this._stepReached[videoData.videoId] || 0) ) {
            this._stepReached[videoData.videoId] = currentStepReached;

            this._analytics.pubSub.publish("videoMarkReached", Object.assign(videoData, {
                "position": Math.round(videoPlayer.currentTime() * 1000),
                "mark": currentStepReached * 25
            }));
        }
    }

    _handleVideoEnteredFullscreen(videoPlayer, videoData) {
        if (!this._hasAlreadyBeenFullscreen[videoData.videoId]) {
            this._analytics.pubSub.publish("videoEnteredFullscreen", Object.assign(videoData, {
                "position": Math.round(videoPlayer.currentTime() * 1000),
            }));

            this._hasAlreadyBeenFullscreen[videoData.videoId] = true;
        }
    }

}
