
import ScrolledToBeginOfBlockHandler from "./ScrolledToBeginOfBlockHandler";

export default class ScrolledToBlockDepthHandler extends ScrolledToBeginOfBlockHandler {

    /**
     * @type {string}
     * @private
     */
    _blockName = "";

    /**
     * @param {string} selector - css selector of the dom node to watch
     */
    constructor(selector, blockName) {
        super(selector, null);

        this._blockName = blockName;
    }

    /**
     * @private
     * @var {array}
     */
    _ratioThresholds = [0.25, 0.5, 0.75, 1];

    _triggerEvent() {
        let scrolledRatio = Math.min(this._calculateBottomPosition() / (this._calculateTargetTopPosition() + this._targetNode.offsetHeight), 1);

        if (scrolledRatio >= this._ratioThresholds[0]) {
            let ratio;
            while (scrolledRatio >= this._ratioThresholds[0]) {
                ratio = this._ratioThresholds.shift();
            }

            const eventData = {
                name: this._blockName,
                reach: parseInt(ratio * 100) + "%",
            };

            this._analytics.pubSub.publish("scrolledToBlockDepth", eventData);
        }

    }
}
