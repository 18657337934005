import HandlerAbstract from "./HandlerAbstract";

export default class AudioHandler extends HandlerAbstract {

    _markReached = {};

    getEventHandlers() {
        return ["audioReady", "audioStarted", "audioUnpaused", "audioPaused", "audioTimeupdate"];
    }

    handle(evt) {
        let audioPlayer = evt.detail.targetAudioPlayer;
        let audioData = {
            audioId: audioPlayer.getAttribute("data-audio-id"),
            audioTitle: audioPlayer.getAttribute("data-audio-title"),
            audioType: audioPlayer.getAttribute("data-audio-type"),
            audioUrl: audioPlayer.getAttribute("data-audio-url"),
            audioUrlHash: audioPlayer.getAttribute("data-audio-url-hash"),
            audioMediaSessionControlsUsed: evt.detail.mediaSessionControlsUsed,
        };
        let methodName = "_handle" + evt.type[0].toUpperCase() + evt.type.slice(1);

        return this[methodName] !== undefined ? this[methodName].call(this, audioPlayer, audioData, evt.detail.targetAudioCmp) : true;
    }

    _handleAudioReady(audioPlayer, audioData) {
        this._analytics.pubSub.publish("audioReady", Object.assign(audioData, {
            "durationInMS": Math.round(audioPlayer.duration() * 1000),
        }));
    }

    _handleAudioStarted(audioPlayer, audioData, audioCmp) {
        this._analytics.pubSub.publish("audioStarted", Object.assign(audioData, {
            "autoplay": audioCmp ? audioCmp.hasAutoplayed() : false,
            "triggeredBy": audioData.audioMediaSessionControlsUsed ? "mediaSessionControls" : "inHousePlayerControls",
        }));
    }

    _handleAudioUnpaused(audioPlayer, audioData) {
        this._handleAudioPlayPauseEvents("audioUnpaused", audioPlayer, audioData);
    }

    _handleAudioPaused(audioPlayer, audioData) {
        this._handleAudioPlayPauseEvents("audioPaused", audioPlayer, audioData);
    }

    _handleAudioPlayPauseEvents(eventName, audioPlayer, audioData) {
        this._analytics.pubSub.publish(eventName, Object.assign(audioData, {
            "triggeredBy": audioData.audioMediaSessionControlsUsed ? "mediaSessionControls" : "inHousePlayerControls",
            "position": Math.round(audioPlayer.currentTime() * 1000),
        }));
    }

    _handleAudioTimeupdate(audioPlayer, audioData) {
        let currentPercent = Math.round(audioPlayer.currentTime() * 100 / audioPlayer.duration());

        let currentMarkReached = 0;
        if (currentPercent >= 25 && currentPercent < 50) {
            currentMarkReached = 25;
        } else if (currentPercent >= 50 && currentPercent < 75) {
            currentMarkReached = 50;
        } else if (currentPercent >= 75 && currentPercent < 90) {
            currentMarkReached = 75;
        } else if (currentPercent >= 90 && currentPercent < 100) {
            currentMarkReached = 90;
        } else if (currentPercent >= 100) {
            currentMarkReached = 100;
        }

        if (currentMarkReached > (this._markReached[audioData.audioId] || 0) ) {
            this._markReached[audioData.audioId] = currentMarkReached;
            this._analytics.pubSub.publish("audioMarkReached", Object.assign(audioData, {
                "position": Math.round(audioPlayer.currentTime() * 1000),
                "mark": currentMarkReached,
            }));
        }
    }
}
