export default class DeviceDetector {

    static isTablet() {

        const userAgent = navigator.userAgent.toLowerCase();

        return /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(userAgent);

    }

}
