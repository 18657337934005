export default class TrendingStats {

    AVIS_DE_NOMINATION_SECTION_ID = "273";
    RENSEIGNEMENTS_SECTION_ID = "283";
    LAPRESSEPLUS_SECTION_ID = "290";
    EN_PHOTOS_SECTION_ID = "291";
    PARIS_MATCH_SECTION_ID = "260";
    SONDAGES_SECTION_ID = "302";

    EXCLUDED_SECTION = [
        this.AVIS_DE_NOMINATION_SECTION_ID,
        this.RENSEIGNEMENTS_SECTION_ID,
        this.LAPRESSEPLUS_SECTION_ID,
        this.EN_PHOTOS_SECTION_ID,
        this.PARIS_MATCH_SECTION_ID,
        this.SONDAGES_SECTION_ID
    ];

    update(sectionId, rootSectionId, storyId, parentId) {
        if (sectionId && rootSectionId && storyId && !this.isSectionExcluded(sectionId)) {
            let url = parentId ? `/trending/${rootSectionId}/${sectionId}/${storyId}/${parentId}` : `/trending/${rootSectionId}/${sectionId}/${storyId}`;
            fetch(url)
                .then((res) => {
                    return res.json();
                });
        }
    }

    isSectionExcluded(sectionId) {
        return this.EXCLUDED_SECTION.indexOf(sectionId) !== -1;
    }
}
