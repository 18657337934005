import {AutoplaySetting} from "./AutoplaySetting";

export default class Autoplay {

    #window;
    #isHeader;
    #autoplaySetting;
    #isMobile;
    #isDossier;

    constructor(window, autoplaySetting, isMobile, isHeader, isDossier) {
        this.#window = window;
        this.#autoplaySetting = autoplaySetting;
        this.#isMobile = isMobile;
        this.#isHeader = isHeader;
        this.#isDossier = isDossier;
    }

    shouldAutoplay() {
        if (this.#shouldPreventAutoplayOnDossierTabChange()) {
            return false;
        }

        // userAgent is used to prevent autoplay on safari v15.x. A bug plays the video on top of ads. see LPE-7527.
        if (this.#isHeader && !this.#window.navigator.userAgent.match("Version/15\.[0-9]+ Safari")) {
            switch (this.#autoplaySetting) {
                case AutoplaySetting.AUTOPLAY:
                    return true;
                case AutoplaySetting.NO_AUTOPLAY:
                    return false;
                default:
                    return !this.#isMobile;
            }
        }

        return false;
    }

    #shouldPreventAutoplayOnDossierTabChange() {
        if (!this.#isDossier) {
            return false;
        }

        return this.#isNavigatingBetweenDossierTabs();
    }

    #isNavigatingBetweenDossierTabs() {
        return this.#getCurrentUrlUpToDateSegment() === this.#getReferrerUrlUpToDateSegment();
    }

    #getCurrentUrlUpToDateSegment() {
        const currentParts = this.#window.location.href.split("/");
        return currentParts.slice(0, currentParts.findIndex(part => /\d{4}-\d{2}-\d{2}/.test(part))).join("/");
    }

    #getReferrerUrlUpToDateSegment() {
        const referrerParts = this.#window.document.referrer.split("/");
        return referrerParts.slice(0, referrerParts.findIndex(part => /\d{4}-\d{2}-\d{2}/.test(part))).join("/");
    }
}
