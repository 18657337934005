import WebPart from "./WebPart";
import lozad from "lozad";

export default class RightColumnManager {

    /**
     * @const
     * @type {Object}
     */
    CONFIG = {
        parallelLoading: 1,
        lozadConf: {
            rootMargin: "0px",
            threshold: 0,
            load: this.loadWebPart.bind(this)
        }
    };

    /**
     * @var {Window}
     * @private
     */
    _window;

    /**
     * @var {Element}
     * @private
     */
    _refItem;

    /**
     * @type {Array<Element>}
     * @private
     */
    _items;

    /**
     * @type {Array<Element>}
     * @private
     */
    _observedItems = [];

    /**
     * @param {string} itemsSelector
     * @param {string} refItemSelector
     * @param {Window} window
     */
    constructor(itemsSelector, refItemSelector, window) {
        this.loadWebPart = this.loadWebPart.bind(this);

        this._window = window;

        this._selector = itemsSelector;

        this._items = window.document.querySelectorAll(this._selector);
        this._refItem = window.document.querySelector(refItemSelector);

        if (this._items.length !== 0 && this._refItem !== null) {
            this.setupScrollHandler();
            this.setupLoading(false);
        }

    }

    setupScrollHandler() {
        const onScroll = () => {
            // remove listener jsut after the first event
            // this snippet is made to handle case when reloading a (scrolled down) page
            this._window.removeEventListener("scroll", onScroll);
            this._shouldTriggerLoading(true);
        };
        this._window.addEventListener("scroll", onScroll);
    }

    /**
     * @param {boolean} lazy
     */
    setupLoading(lazy) {
        let index = 0;

        while (this._observedItems.length < this.CONFIG.parallelLoading && index < this._items.length) {
            if (!this._isLoaded(this._items[index])) {
                this._observedItems.push(this._items[index]);
                this._items[index].classList.add("toload");
            }
            index++;
        }

        if (!lazy) {
            this._observedItems.forEach(item => this.loadWebPart(item));
        } else {
            // .toload class should not be necessary, but lozad doesn't handle NodeList as selector
            (lozad(this._selector + ".toload", this.CONFIG.lozadConf)).observe();
        }

    }

    /**
     * @param {Element} element
     */
    loadWebPart(element) {
        (
            new WebPart(
                this._window.document,
                element,
                this._onWebPartLoaded.bind(this, element),
                false,
                true
            )
        ).load();

        this._markAsLoaded(element);
    }

    _onWebPartLoaded(element) {
        element.classList.remove("toload");
        this._observedItems.splice(this._observedItems.indexOf(element), 1);
        this._shouldTriggerLoading();
    }

    /**
     * @param {boolean} forceCheck
     * @private
     */
    _shouldTriggerLoading(forceCheck = false) {
        if (forceCheck || this._observedItems.length === 0) {
            let maxHeight = this._getPageRelativeOffsetBottom(this._refItem);
            let contentOffsetBottom = this._getPageRelativeOffsetBottom(this._items[this._items.length - 1]);

            if (contentOffsetBottom < maxHeight) {
                this.setupLoading(contentOffsetBottom >= this._getWiewportBottom());
            }
        }
    }

    /**
     * @returns {number}
     * @private
     */
    _getWiewportBottom() {
        return this._window.document.documentElement.scrollTop + this._window.innerHeight;
    }

    /**
     * @param {Element} element
     * @returns {number}
     * @private
     */
    _getPageRelativeOffsetBottom(element) {
        let topPosition = this._getPageRelativeOffsetTop(element);

        return topPosition + element.offsetHeight;
    }

    /**
     * @param {Element} element
     * @returns {number}
     * @private
     */
    _getPageRelativeOffsetTop(element) {
        let topPosition = element.offsetTop;
        let offsetParent = element.offsetParent;

        while (offsetParent) {
            topPosition += offsetParent.offsetTop;
            offsetParent = offsetParent.offsetParent;
        }

        return topPosition;
    }

    /**
     * @param {Element} element
     * @returns {boolean}
     * @private
     */
    _isLoaded(element) {
        return element.getAttribute("data-loaded") === "true";
    }

    /**
     * @param {Element} element
     * @private
     */
    _markAsLoaded(element) {
        element.setAttribute("data-loaded", true);
    }

}
