import Logger from "../Logger";

const logger = new Logger(console, ["Media", "MediaPlayerManager"]);

logger.debug("module loaded");

export default class MediaPlayerManager {
    _players = [];

    constructor() {
        logger.debug("constructor");
    }

    push(mediaPlayer) {
        logger.debug("push mediaPlayer %o", mediaPlayer);
        mediaPlayer.onPlay = this.pauseAll.bind(this, [mediaPlayer]);

        this._players.push(mediaPlayer);
    }

    pauseAll(except = []) {
        logger.debug("pauseAll except %o", except);
        this._players
            .filter(player => except.indexOf(player) === -1)
            .filter(player => !player.muted())
            .forEach(player => {
                player.pause();
            });
    }
}
