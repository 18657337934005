export default {

    setTopPosition: function() {
        this._setPaddingTop();
    },

    _setPaddingTop: function() {
        const articlePositionTop = this._getOffsetBySelector(".mainContent");

        const visualPositionTop = this._getOffsetBySelector(".mainStory header .visual");
        if (articlePositionTop && visualPositionTop) {
            const positionTop = visualPositionTop.top - articlePositionTop.top;
            this._setAsidePaddingTop(positionTop);

            return;
        }

        const leadPositionTop = this._getOffsetBySelector(".mainStory .textModule--type-lead");
        if (articlePositionTop && leadPositionTop) {
            const positionTop = leadPositionTop.top - articlePositionTop.top + 6;
            this._setAsidePaddingTop(positionTop);

            return;
        }

        const bodyPositionTop = this._getOffsetBySelector(".mainStory .articleBody");
        if (articlePositionTop && bodyPositionTop) {
            const positionTop = bodyPositionTop.top - articlePositionTop.top + 6;
            this._setAsidePaddingTop(positionTop);

            return;
        }
    },

    _setAsidePaddingTop: function(positionTop) {
        const element = document.querySelector("aside.col");

        if (!element) {
            return;
        }

        element.style.paddingTop = positionTop + "px";
    },

    _getOffsetBySelector: function(selector) {
        const element = document.querySelector(selector);

        if (!element) {
            return 0;
        }

        const rect = element.getBoundingClientRect();

        return {
            top: rect.top + window.scrollY,
            left: rect.left + window.scrollX,
        };
    },
};
