export default class VideoImaOptionsBuilder {

    _url = "https://pubads.g.doubleclick.net/gampad/ads";

    /**
   * @param data
   * @param data.elementId
   * @param data.videoPosition
   * @param data.videoDuration
   * @param data.network
   * @param data.topLevelAdUnit
   * @param data.adUnit
   * @param data.location
   * @param data.isShowCountdown
   * @param data.userAgent
   * @param data.storyAdsKeyValues
   * @param data.pageAdsKeyValues
   * @param data.deviceInstanceIdHash
   * @param data.readerIdHash
   *
   */
    constructor(data) {
        this._elementId = data.elementId;
        this._videoPosition = data.videoPosition;
        this._videoDuration = data.videoDuration;
        this._network = data.network;
        this._topLevelAdUnit = data.topLevelAdUnit;
        this._adUnit = data.adUnit;
        this._location = data.location;
        this._isShowCountdown = data.isShowCountdown;
        this._storyAdsKeyValues = data.storyAdsKeyValues;
        this._pageAdsKeyValues = data.pageAdsKeyValues;
        this._deviceInstanceIdHash = data.deviceInstanceIdHash;
        this._readerIdHash = data.readerIdHash;
    }

    _getValueForDuration() {
        switch (true) {
            case this._videoDuration >= 121000:
                return "video121plus";
            case this._videoDuration >= 91000:
                return "video91-120";
            case this._videoDuration >= 61000:
                return "video61-90";
            case this._videoDuration >= 31000:
                return "video31-60";
            default:
                return "video0-30";
        }
    }

    _getCustomParams() {

        const customParams = {
            position: "pos" + this._videoPosition,
            pageBlock: "text",
            videoDuration: this._getValueForDuration(),
            platform: "lpca",
            deviceInstanceIdHash: this._deviceInstanceIdHash,
            PPIDHash: this._deviceInstanceIdHash,
        };

        if (this._readerIdHash) {
            customParams.readerIdHash = this._readerIdHash;
        }

        const customParamsWithStoryParams = Object.assign(customParams, this._storyAdsKeyValues);

        return Object.assign(customParamsWithStoryParams, this._pageAdsKeyValues);
    }

    _getCustomParamsUrlEncoded() {
        const params = this._getCustomParams();
        return Object.keys(params).map((key) => {
            return [key, params[key]].join("%3D");
        }).join("%26");
    }

    _getTags() {
        return {

            // Size
            sz: "640x480", // video size

            // AdUnit
            iu: "/" + [this._network, this._topLevelAdUnit, this._adUnit].join("/"),

            // Spec
            gdfp_req: 1,
            env: "vp",
            output: "vast",
            unviewed_position_start: 1,
            vpmute: 0,
            plcmt: 1,

            // KeyValue
            cust_params: this._getCustomParamsUrlEncoded(),

            // Url Description
            description_url: encodeURIComponent(this._location.protocol + "//" + this._location.hostname + this._location.pathname),

            // Header Language
            hl: "fr",
        };
    }

    _getTagUrlEncoded() {
        const tags = this._getTags();
        return this._url + "?" + Object.keys(tags).map(function(key) {
            return [key, tags[key]].join("=");
        }).join("&");
    }

    getOptions() {
        return {
            id: this._elementId,
            adLabel: "Publicité",
            adLabelNofN: "de",
            locale: "fr",
            showCountdown: this._isShowCountdown,
            vastLoadTimeout: 7000,
            adTagUrl: this._getTagUrlEncoded(),
            contribAdsSettings: {
                timeout: 7000,
                prerollTimeout: 7000,
            },
        };
    }

}
