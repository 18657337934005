/* eslint */
/* global videojs */

import {LightBoxVideo} from "./LightBoxVideo";
import {getFeatureByName} from "../Feature";

export default class VideoComponent {

    _videoElement = null;
    _player = null;
    _paused = null;
    _overlay = null;
    _lightBoxVideo = null;
    _adsManager = null;

    constructor(videoElement, videoImaOptions = null, shouldAutoplay) {

        this._videoElement = videoElement;

        this._player = videojs(
            this.getVideoPlayerId(),
            {
                autoplay: shouldAutoplay,
                loop: videoElement.dataset?.loop ? videoElement.dataset.loop.toLowerCase() === "true" : false,
                muted: videoElement.dataset?.muted ? videoElement.dataset.muted.toLowerCase() === "true" : false,
                controls: videoElement.dataset?.controls ? videoElement.dataset.controls.toLowerCase() === "true" : true,
                languages: {
                    fr: {
                        "Play": "Lecture",
                        "Pause": "Pause",
                        "Play Video": this.getDurationInMinutesAndSeconds(),
                        "Mute": "Désactiver le son",
                        "Unmute": "Activer le son",
                        "Fullscreen": "Plein écran",
                    },
                },
            },
        );

        if (videoImaOptions) {
            if ("function" === typeof this._player.ima) {
                if (getFeatureByName("videojs-ima.debug").enabled) {
                    videoImaOptions.debug = true;
                }
                console.groupCollapsed("VideoComponent %o", videoElement.id);
                console.debug(videoElement);
                console.debug("videojs-ima.debug: %o", videoImaOptions.debug);
                console.groupEnd();
                this._player.ima(videoImaOptions);

                // Remove controls from the player on iPad to stop native controls from stealing
                // our click
                if ((navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/Android/i)) && this._videoElement.hasAttribute("controls")) {
                    this._videoElement.removeAttribute("controls");
                }

                // Initialize the ad container when the video player is clicked, but only the
                // first time it's clicked.
                let initAdDisplayContainer = () => {
                    this._player.ima.initializeAdDisplayContainer();
                    wrapperDiv.removeEventListener("click", initAdDisplayContainer);
                    wrapperDiv.removeEventListener("touchend", initAdDisplayContainer);
                };

                let wrapperDiv = document.getElementById(this.getVideoPlayerId());
                wrapperDiv.addEventListener("click", initAdDisplayContainer);
                wrapperDiv.addEventListener("touchend", initAdDisplayContainer);
            }
        }
        this._player.on("playing", () => {
            if(navigator.mediaSession)navigator.mediaSession.metadata=null;
        });
        if (this._videoElement.closest(".lightBoxVideo")) {
            this._lightBoxVideo = new LightBoxVideo(this._videoElement.closest(".lightBoxVideo").parentNode, this);
        } else {
            // overlay is intended to capture first user interaction to determine if the first playback has been triggered by user or not
            this._onClickOverlay = this._onClickOverlay.bind(this);
            this._clearOverlay = this._clearOverlay.bind(this);
            this._createOverlay();
        }

        this._registerVideoEvents();
    }

    onPlay = () => {};

    play() {
        if (this._adsManager?.getRemainingTime() > 0) {
            this.onPlay();
            this._player.ima.resumeAd();
        }else {
            this._player.play();
        }
    }

    pause() {
        if(this._adsManager) {
            this._adsManager.pause();
        }
        this._player.pause();
    }

    muted() {
        return this._player.muted();
    }

    getDurationInMinutesAndSeconds() {
        let millis = this._videoElement.getAttribute("data-video-duration");
        let minutes = Math.floor(millis / 60000);
        let seconds = ((millis % 60000) / 1000).toFixed(0);
        return minutes + ":" + (seconds < 10 ? "0" : "") + seconds;
    }

    getVideoPlayerId() {
        return this._videoElement.id;
    }

    hasAutoplayed() {
        return this._overlay !== null;
    }

    _createOverlay() {
        const overlay = this._videoElement.ownerDocument.createElement("div");
        overlay.style.position = "absolute";
        overlay.style.width = "100%";
        overlay.style.height = "100%";
        overlay.style.top = "0";
        overlay.style.cursor = "pointer";
        overlay.classList.add("video-overlay");

        overlay.addEventListener("click", this._onClickOverlay);

        this._overlay = overlay;
        this._videoElement.parentNode.appendChild(overlay);
    }

    _onClickOverlay() {
        /**
         * Delaying Execution: By using setTimeout with a short delay,
         * you are essentially moving your code to the back of the execution queue.
         * This allows other synchronous tasks, event handlers, or rendering processes to complete before your code runs.
         */
        setTimeout(() => {
            this._clearOverlay();
            this._player.play();
        }, 1);
    }

    _clearOverlay() {
        if (this._overlay !== null) {
            this._overlay.removeEventListener("click", this._onClickOverlay);
            this._overlay.parentNode.removeChild(this._overlay);
            this._overlay = null;
        }
    }

    _registerVideoEvents() {
        this._player.one("loadedmetadata", this._generateEventHandler("videoReady"));
        this._player.one("play", this._generateEventHandler("videoStarted", () => { this.onPlay(); this._clearOverlay(); }));
        this._player.on("play", this._generateEventHandler("videoUnpaused",() => { this.onPlay(); this._paused = false; }, this._hasBeenPaused));
        this._player.on("pause", this._generateEventHandler("videoPaused",() => { this._paused = true; }));
        this._player.on("timeupdate", this._generateEventHandler("videoTimeupdate"));
        this._player.on("fullscreenchange", this._generateEventHandler("videoEnteredFullscreen", () => {},  this._isFullscreen));
        this._player.on("ads-manager", (response) => {
            this._adsManager = response.adsManager;
            this._registerAdEvents();
        });
    }

    _registerAdEvents() {
        var events = [
            /*google.ima.AdEvent.Type.ALL_ADS_COMPLETED,
            google.ima.AdEvent.Type.COMPLETE,
            google.ima.AdEvent.Type.FIRST_QUARTILE,
            google.ima.AdEvent.Type.LOADED,
            google.ima.AdEvent.Type.MIDPOINT,
            google.ima.AdEvent.Type.PAUSED,
            google.ima.AdEvent.Type.THIRD_QUARTILE,*/
            google.ima.AdEvent.Type.RESUMED,
            google.ima.AdEvent.Type.STARTED
        ];

        for (var index = 0; index < events.length; index++) {
            this._player.ima.addEventListener(
                events[index],
                this._onAdEvent.bind(this));
        }
    }

    _onAdEvent(event) {
        switch (event.type.toUpperCase()) {
            case "RESUME":
            case "START":
                this.onPlay();
                break;
        }
    }

    /**
     * this event handler generator has been implented to be able to unit test handlers
     *
     * @param {string} eventName - name of the event the returned handler could trigger
     * @param {Function} eventCallback
     * @param {Function} condition - should return true or false
     */
    _generateEventHandler(eventName,  eventCallback = () => {}, condition = null) {
        return () => {
            let isConditionValid = typeof condition === "function" ? condition.call(this) : true;
            if (isConditionValid) {
                this._dispatchEvent(this._generateEvent(eventName));
                eventCallback();
            }

        };
    }

    _hasBeenPaused() {
        return this._paused === true;
    }

    _isFullscreen() {
        return this._player.isFullscreen();
    }

    _generateEvent(eventName) {
        let evt = new CustomEvent(eventName, { bubbles: true });

        evt.targetVideoCmp = this;
        evt.targetVideoPlayer = this._player;

        return evt;
    }

    _dispatchEvent(e) {
        const win = this._videoElement.ownerDocument.defaultView || this._videoElement.parentWindow;
        win && win.dispatchEvent(e);
    }
}
