
import HandlerAbstract from "./HandlerAbstract";

export default class SocialShareHandler extends HandlerAbstract {

    getEventHandlers() {
        return ["mouseup"];
    }

    handle(evt) {

        const target = evt.target;

        this._analytics.pubSub.publish("shareButtonClicked",{
            location: target.closest("[data-location]").dataset.location,
            network: target.closest("li").className
        });

        return false;
    }

}
